import http from '@admin/http';
import { AxiosInstance } from 'axios';

interface Params {
  csvFile: File;
  groupId: string;
}

function uploadPaymentPlansCtor(http: AxiosInstance) {
  return async ({ csvFile, groupId }: Params) => {
    const formData = new FormData();
    formData.append('csv', csvFile);
    const { data } = await http.post(`/lnpl/v1/payment-plan-groups/${groupId}/upload/csv`, formData);
    return data;
  };
}

export const uploadPaymentPlans = uploadPaymentPlansCtor(http);
