










































































































































































































import Vue from 'vue';
import { required, requiredIf } from 'vuelidate/lib/validators';
import WbTextField from '@/components/text_field/index.vue';
import WbSelect from '@/components/select/index.vue';
import qualify from './dependencies/qualify';
import getQualificationInfo from './dependencies/get_qualification_info';
import WbDialog from '@/components/dialog/index.vue';
import WbCountry from '@/components/country_dropdown/index.vue';
import { sortCountries } from '@/components/country_dropdown/helpers';
import getRegions from './dependencies/get_regions';
import WbAddressAutoComplete from '@/components/address_autocomplete/index.vue';
import WbDisclosure from '../../../components/disclosure/index.vue';

interface Form {
  country: Country;
  city: string;
  street: string;
  state: string | null;
  postalCode: string;
  complement: string;
  overrideAddressVerification: boolean;
  acceptedDisclosure: boolean;
  acceptedAuthorization: boolean;
  acceptedTerms: boolean;
}

interface Country {
  id: string;
  name: string;
  callingCode: number;
  requiresPostalCode: boolean;
}

export default Vue.extend({
  name: 'KnowYourCustomerAddress',

  components: {
    WbTextField,
    WbSelect,
    WbDialog,
    WbCountry,
    WbAddressAutoComplete,
    WbDisclosure,
  },

  props: {
    qualificationState: {
      type: String,
    },

    applicationState: {
      type: Object,
    },

    demo: {
      type: Boolean,
    },

    ppmOnly: {
      type: Boolean,
    },
  },

  data() {
    return {
      error: '',
      mounted: false,
      state: '',
      sessionId: '',
      studentId: '',
      countries: [] as Country[],
      regions: [],
      suggestionComplete: false,
      form: {
        country: {} as any,
        city: '',
        street: '',
        state: '',
        postalCode: '',
        complement: '',
        overrideAddressVerification: false,
        acceptedTerms: false,
        acceptedDisclosure: false,
        acceptedAuthorization: false,
      } as Form,
      suggestedAddress: {} as Form,
      suggestedAddressDialog: false,
      accountCreationActionText: this.ppmOnly ? 'Continue to checkout' : 'Submit Application',
    };
  },

  computed: {
    disableInputs(): boolean {
      return this.applicationState.creditCheckStatus === 'completed'
        && this.applicationState.kycStatus === 'completed';
    },
    submitDisabled(): boolean {
      if (this.$v.form.$invalid) return true;

      if (this.state === 'loading') return true;

      return false;
    },
    regionNotNeeded(): boolean {
      return !this.regions.length;
    },
  },

  validations() {
    const isTrue = (value) => value === true;
    return {
      form: {
        city: { required },
        street: { required },
        state: {
          // @ts-ignore
          required: requiredIf(() => !this.regionNotNeeded),
        },
        postalCode: {
          // @ts-ignore
          required: requiredIf((vm) => vm?.country?.requiresPostalCode ?? true),
        },
        acceptedTerms: { required, isTrue },
        acceptedDisclosure: { required, isTrue },
        acceptedAuthorization: { required, isTrue },
      },
    };
  },

  watch: {
    'form.country.id': async function (newVal) {
      if (newVal && this.mounted && !this.disableInputs) {
        const regions = await getRegions(newVal);
        this.regions = regions.map((r) => ({ name: r.name, code: r.id }));

        this.form.city = '';
        this.form.street = '';
        this.form.state = '';
        this.form.postalCode = '';
        this.form.complement = '';
      }
    },
  },

  async mounted() {
    const { projectId, sessionId } = this.$route.params;
    const {
      country,
      city,
      street,
      state,
      postalCode,
      complement,
      data,
    } = await getQualificationInfo(projectId, sessionId);

    this.countries = sortCountries(data.countries);

    if (country) {
      this.form.country = this.countries.find((c) => c.id === country.toUpperCase()) || this.countries[0];
      const regions = await getRegions(this.form.country.id);
      this.regions = regions.map((r) => ({ name: r.name, code: r.id }));
    } else {
      const defaultCountry = this.countries.find(
        (country) => country.id === data.latestSessionCountry,
      ) || this.countries[0];
      this.form.country = defaultCountry;
      const regions = await getRegions(this.form.country.id);
      this.regions = regions.map((r) => ({ name: r.name, code: r.id }));
    }

    if (this.demo) return;

    if (
      city
      || street
      || state
      || postalCode
      || complement
    ) {
      this.suggestionComplete = true;
    }

    this.form.city = city;
    this.form.street = street;
    this.form.state = state?.toUpperCase();
    this.form.postalCode = postalCode;
    this.form.complement = complement;

    this.mounted = true;
  },

  methods: {
    async submitForm() {
      if (!this.demo && this.qualificationState === 'qualified') {
        this.$router.push({ path: 'payment-plan', query: this.$route.query });
        return;
      }

      this.error = '';
      this.$v.form.$touch();

      if (this.$v.form.$error) { return; }
      this.state = 'loading';

      const { projectId, sessionId } = this.$route.params;
      try {
        const { risk } = await qualify(projectId, sessionId, {
          ...this.form,
          country: this.form.country.id,
        });

        if (risk.result === 'qualified') {
          // route to payment directly if api gets a single plan, and assigns it
          this.$router.push({ path: 'payment-plan', query: this.$route.query });
        } else {
          let path = '';

          if (risk.messages.includes('credit_check_ssn_needed')) {
            path = 'unlock-credit-profile';
          } else if (risk.messages.includes('credit_check_locked')) {
            path = 'locked-credit-profile';
          } else if (risk.messages.includes('credit_check_frozen')) {
            path = 'frozen-credit-profile';
          } else if (risk.messages.includes('location_check_cant_be_served')) {
            path = 'outside-service';
          } else if (risk.messages.includes('credit_check_manual_review_needed')) {
            path = 'manual-review';
          } else {
            path = 'order-declined';
          }

          this.$router.replace({ path, query: this.$route.query });
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        this.state = 'loaded';
      }
    },

    handler(data) {
      this.suggestionComplete = true;
      this.form.city = data.city;
      this.form.street = data.street;
      this.form.state = data.regionCode;
      this.form.complement = data.complement;
      this.form.postalCode = data.postal;
    },

    overrideAddressVerification() {
      this.form.overrideAddressVerification = true;
      this.suggestedAddressDialog = false;
      this.$emit('setErrorMessage', null);
      this.submitForm();
    },

    applySuggestedAddress() {
      this.form = this.suggestedAddress;
      this.form.overrideAddressVerification = true;
      this.suggestedAddressDialog = false;
      this.$emit('setErrorMessage', null);
      this.submitForm();
    },

    validateAddressField(input: string) {
      if (!this.$v?.form?.[input]?.$dirty) {
        return [];
      }
      if (!this.$v?.form?.[input]?.required) {
        return ['Field Required'];
      }
      return [];
    },

    handleError(error: any) {
      if (error?.data?.type === 'AddressVerificationUnsuccessfull') {
        const verificationStatus = error.data.info.status;

        if (verificationStatus !== 'verified') {
          if (verificationStatus === 'corrected') {
            this.suggestedAddress = error.data.info.correction;
            this.suggestedAddressDialog = true;
          }

          this.$emit('setErrorMessage', `
            We had trouble verifying your address.
            Confirm the information you entered is correct
            before continuing.
          `);

          this.form.overrideAddressVerification = true;
        }
      } else {
        this.$emit('error', error);
      }
    },
  },
});
