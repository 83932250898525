




































































































































import Vue from 'vue';

import PaymentPlanButton from './components/payment_plan_button/index.vue';
import getProductsAndPaymentPlans, { PaymentPlan } from './dependencies/get_products_and_payment_plans';
import setProductAndPaymentPlan from './dependencies/set_product_and_payment_plan';

export default Vue.extend({
  name: 'PaymentPlanSelection',

  components: {
    PaymentPlanButton,
  },
  props: {
    checkoutV2Enabled: {
      type: Boolean,
      default: false,
    },
    projectData: {
      type: Object,
    },
    origin: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      state: '',
      selectedPlanId: '',
      selectedPlanIndex: 0,
      checkoutName: '',
      paymentPlans: [] as unknown as PaymentPlan[],
      isPaymentPlansAvailable: false,
      filteredProductsMessages: [] as any[],
    };
  },

  watch: {
    selectedPlanIndex() {
      this.selectedPlanId = this.paymentPlans[this.selectedPlanIndex].id;
    },
  },

  async mounted() {
    await this.loadPaymentPlans();
  },

  methods: {
    async loadPaymentPlans() {
      const { projectId, sessionId } = this.$route.params;

      try {
        this.state = 'loading';
        const data = await getProductsAndPaymentPlans(projectId, sessionId);
        this.paymentPlans = data.paymentPlans || [];

        this.selectedPlanIndex = 0;
        this.selectedPlanId = this.paymentPlans[0].id;

        this.isPaymentPlansAvailable = this.paymentPlans.length > 0;
        this.checkoutName = data.checkoutSession.checkoutName;
        this.$emit('setOrigin', this.paymentPlans[0].origin);

      // @ts-ignore
      } catch (error: any) {
        if (error.data?.type === 'MissingEntryProductId' || error.data?.type === 'MissingProduct') {
          this.$emit('setErrorMessage', `
            Hey there, thanks for your interest in Elective! You have been approved
            in our system but it doesn’t look like there is an active product for
            sale here. Please confirm with the merchant that the URL provided is correct. Thank you!
          `);
        } else if (error.data?.type === 'TooManyProductsReturned') {
          this.$emit('setErrorMessage', `
            An error occured loading the payment plans. If this persists please reach out to
            our support team at help@elective.com.
          `);
        }
      } finally {
        this.state = 'loaded';
      }
    },

    async submit(e: Event) {
      e.preventDefault();

      try {
        this.state = 'submitting';
        await setProductAndPaymentPlan({
          projectId: this.$route.params.projectId,
          sessionId: this.$route.params.sessionId,
          paymentPlanId: this.selectedPlanId,
        });

        const checkoutV2Enabled = this.checkoutV2Enabled ?? false;

        // navigate to checkout v2
        const checkoutV2Base = process.env.VUE_APP_CHECKOUT_URL;

        // only route to v2 if the source is not v2 (ie not coming from v2)
        if (checkoutV2Base && checkoutV2Enabled) {
          const url = new URL(checkoutV2Base);
          const { projectId, sessionId } = this.$route.params;
          const { productId } = this.$route.query;

          const pathSegments = [
            'c',
            projectId,
            productId,
          ];

          url.searchParams.set('origin', 'lnpl');
          url.searchParams.set('sid', sessionId);

          url.pathname = pathSegments.join('/');

          window.location.replace(url.toString());
          return;
        }

        this.$router.push({ path: 'payment', query: this.$route.query });
      } catch (error) {
        this.handleError(error);
      }
    },

    async handleError(error: any) {
      this.$emit('error', error);
    },
  },
});
