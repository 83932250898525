import { AxiosInstance } from 'axios';
import http from '@/views/student/http';

export interface PaymentPlan {
  id: string;
  installments: number;
  firstPaymentInCents: number;
  recurringAmount: number;
  currencyCode: 'USD' | 'CAD';
  totalPrice: number;
  origin: string;
  taxAmountInCents: number | null;
  hasDownPayment: boolean;
  apr: number;
  expectedInterestInCents: number;
}

interface GetProductAndPaymentPlans {
  checkoutSession: any;
  paymentPlans: PaymentPlan[];
}

function getPaymentPlansCtor(http: AxiosInstance) {
  return async function getPaymentPlans(projectId: string, sessionId: string): Promise<GetProductAndPaymentPlans> {
    const { data } = await http.get(`/checkout/${projectId}/${sessionId}/product-payment-plans`);
    return data;
  };
}

export default getPaymentPlansCtor(http);
