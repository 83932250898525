<template>
  <wb-content-wrapper
    :state="state"
  >
    <div class="page">
      <h1>Credit Reports</h1>

      <!-- manual refresh button (call getData) -->
      <div>
        <v-btn
          x-small
          color="red"
          class="white--text"
          @click="getData"
        >
          Manual Refresh
        </v-btn>
      </div>

      <div class="callout">
        <h5>How to use this page</h5>

        <div class="callout-content">
          <ul>
            <li>
              Credit reports are split into two distinctive tasks: <strong>Generating the data</strong>
              and <strong>furnishing the data</strong>.
            </li>
            <li>
              The <strong>Generating the data</strong> task is used to generate the data for the credit
              report, and happens automatically on the first day of the month.
            </li>
            <li>
              The <strong>furnishing the data</strong> task is used to furnish the data for the credit
              report based on the data generated by the <strong>Generating the data</strong> task. This
              task is triggered by the generation, but can also be triggered manually in the table below
              via the <strong>Furnish</strong> button.
            </li>
            <li>
              If the <strong>Download</strong> button is disabled, then the report url is not available.
              To remedy this, you can click the furnish button to have a new csv generated and saved to
              s3. Note that the furnish simply triggers the task, and so give it a few minuites max to
              completely finish. Refreshing the page regularly will allow you to see the url become
              available.
            </li>
            <li>
              You can click the <strong>Furnish</strong> button as many times as you want, as it does not
              change any data. It only generates a new csv and updates the url in the database. New reports
              will be prefixed with the version number, and all reports are saved in s3.
            </li>
          </ul>
        </div>
      </div>

      <!-- elective group usa table -->
      <div class="tables">
        <section class="card">
          <h3 class="font-weight-black">
            Elective Group USA
          </h3>

          <v-data-table
            :headers="reportTable.headers"
            :items="electiveGroupUsaReports"
            :items-per-page="reportTable.itemsPerPage"
          >
            <template v-slot:item.reportDate="{ item }">
              {{ item.reportDate | formatDate('MMM dd, H:MM') }}
            </template>

            <template v-slot:item.startDate="{ item }">
              {{ item.startDate | formatDate('MMM dd, H:MM') }}
            </template>

            <template v-slot:item.endDate="{ item }">
              {{ item.endDate | formatDate('MMM dd, H:MM') }}
            </template>

            <template v-slot:item.download="{ item }">
              <v-btn
                x-small
                color="red"
                class="white--text"
                :disabled="!item.url"
                @click="handleDownloadReport(item.url)"
              >
                Download
              </v-btn>
            </template>

            <template v-slot:item.furnish="{ item }">
              <v-btn
                x-small
                color="red"
                class="white--text"
                @click="handleFurnishReport({ reportId: item.id, devMode: false })"
              >
                Furnish
              </v-btn>
            </template>

            <template v-slot:item.devMode="{ item }">
              <v-btn
                x-small
                color="red"
                class="white--text"
                @click="handleFurnishReport({ reportId: item.id, devMode: true })"
              >
                Dev Furnish
              </v-btn>
            </template>
          </v-data-table>
        </section>

        <!-- gnosis labs table -->
        <section class="card ">
          <h3 class="font-weight-black">
            Gnosis Labs
          </h3>

          <v-data-table
            :headers="reportTable.headers"
            :items="gnosisLabsReports"
            :items-per-page="reportTable.itemsPerPage"
          >
            <template v-slot:item.reportDate="{ item }">
              {{ item.reportDate | formatDate('MMM dd, H:MM') }}
            </template>

            <template v-slot:item.startDate="{ item }">
              {{ item.startDate | formatDate('MMM dd, H:MM') }}
            </template>

            <template v-slot:item.endDate="{ item }">
              {{ item.endDate | formatDate('MMM dd, H:MM') }}
            </template>

            <template v-slot:item.download="{ item }">
              <v-btn
                x-small
                color="red"
                class="white--text"
                :disabled="!item.url"
                @click="handleDownloadReport(item.url)"
              >
                Download
              </v-btn>
            </template>

            <template v-slot:item.furnish="{ item }">
              <v-btn
                x-small
                color="red"
                class="white--text"
                @click="handleFurnishReport({ reportId: item.id, devMode: false })"
              >
                Furnish
              </v-btn>
            </template>

            <template v-slot:item.devMode="{ item }">
              <v-btn
                x-small
                color="red"
                class="white--text"
                @click="handleFurnishReport({ reportId: item.id, devMode: true })"
              >
                Dev Furnish
              </v-btn>
            </template>
          </v-data-table>
        </section>
      </div>
    </div>
  </wb-content-wrapper>
</template>

<script>
import Vue from 'vue';
import { getReports, furnishReport } from './dependencies';
import WbContentWrapper from '@/components/content_wrapper/index.vue';

export default Vue.extend({
  components: {
    WbContentWrapper,
  },
  data() {
    return {
      state: 'loading',
      electiveGroupUsaReports: [],
      gnosisLabsReports: [],
      reportTable: {
        itemsPerPage: 25,
        headers: [
          { text: 'ID', value: 'id', sortable: false },
          { text: 'Entity', value: 'reportingEntity', sortable: false },
          { text: 'Report Name', value: 'name', sortable: false },
          { text: 'Report Date', value: 'reportDate', sortable: false },
          { text: 'Start Date', value: 'startDate', sortable: false },
          { text: 'End Date', value: 'endDate', sortable: false },
          { text: 'Download', value: 'download', sortable: false },
          { text: 'Furnish', value: 'furnish', sortable: false },
          { text: 'Dev Furnish', value: 'devMode', sortable: false },
        ],
      },
    };
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    async getData() {
      try {
        this.state = 'loading';
        const reports = await getReports();

        this.electiveGroupUsaReports = reports.filter((report) => report.reportingEntity === 'Elective Group USA Inc.');
        this.gnosisLabsReports = reports.filter((report) => report.reportingEntity === 'Gnosis Labs');

        this.state = 'loaded';
      } catch (error) {
        this.state = 'error';
      }
    },

    handleDownloadReport(url) {
      window.open(url, '_blank');
    },

    async handleFurnishReport({ reportId, devMode }) {
      await furnishReport(reportId, devMode);
    },
  },
});
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.tables {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.callout {
  background-color: #f0f0f0;
  padding: 24px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .callout-content {
    font-size: 14px;
    color: #666;
  }
}
</style>
