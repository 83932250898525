import http from '@admin/http';
import { AxiosInstance } from 'axios';

function downloadPaymentPlanGroupActivePlansCtor(http: AxiosInstance) {
  return async (groupId: string): Promise<Blob> => {
    const { data } = await http.get<Buffer>(`/lnpl/v1/payment-plan-groups/${groupId}/active-plans/csv`, {
      responseType: 'arraybuffer',
    });

    return new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
  };
}

export const downloadPaymentPlanGroupActivePlans = downloadPaymentPlanGroupActivePlansCtor(http);
