

























import Vue from 'vue';
import Illustration from './components/declined_graphic.vue';

export default Vue.extend({

  components: {
    Illustration,
  },
  props: {
    projectData: {
      type: Object,
    },
  },

  data() {
    return {
      projectId: '',
    };
  },

  mounted() {
    this.projectId = String(this.$route.params.projectId);
  },
});
