import { AxiosInstance } from 'axios';
import http from '@/views/admin/http';

interface AuthSessionResponse {
  code: string;
  id: string;
}

function getOrCreateAuthSessionCtor(http: AxiosInstance) {
  return async (): Promise<AuthSessionResponse> => {
    const { data } = await http.post<AuthSessionResponse>('/auth/v1/generate-auth-session');
    return data;
  };
}

export const getOrCreateAuthSession = getOrCreateAuthSessionCtor(http);
