



























































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import WbDialog from '@/components/dialog/index.vue';
import WbTextField from '@/components/text_field/index.vue';
import WbTextArea from '@/components/text_area/index.vue';
import WbSelect from '@/components/select/index.vue';
import WbContentWrapper from '@/components/content_wrapper/index.vue';
import manualReviewCreditCheck, { ManualReviewForm } from './dependencies/manual_review_credit_check';
import archiveCreditCheck from './dependencies/archive_credit_check';
import archiveLoanApplication from './dependencies/archive_loan_application';
import archiveKyc from './dependencies/archive_kyc_form';
import updateCreditCheck from './dependencies/update_credit_check';
import getStudent, {
  Info, Kyc, LoanOrder, CreditCheck, CheckoutSession, Application,
} from './dependencies/get_student';
import { updateStudent } from './dependencies/update_student';

interface UpdateCreditCheckForm {
  source: string;
  hitStrength?: string;
  notes?: string;
}

interface Student {
  info: Info;
  creditCheck: CreditCheck;
  kycForm: Kyc;
  loanOrders: LoanOrder[];
  loanOrderApplications: Application[];
  checkoutSessions: CheckoutSession[];
}

export default Vue.extend({

  components: {
    WbDialog,
    WbTextField,
    WbSelect,
    WbTextArea,
    WbContentWrapper,
  },
  data() {
    return {
      state: '',
      student: {} as Student,
      creditCheckResponseDialogSoft: false,
      creditCheckResponseDialogHard: false,

      archiveCreditCheckDialog: false,
      archiveCreditCheckState: '',

      archiveLoanOrderApplicationDialog: false,
      archiveLoanOrderApplicationDialogStagedId: '',
      archiveLoanOrderApplicationDialogState: '',

      archiveKycDialog: false,
      archiveKycState: '',

      updateCreditCheckDialog: false,

      updateCreditCheckState: '',
      updateCreditCheckForm: {
        source: 'admin',
        hitStrength: undefined,
        notes: '',
      } as UpdateCreditCheckForm,
      manualReviewDialog: false,
      manualReviewState: '',
      manualReviewForm: {
        source: 'admin',
        status: 'completed',
        notes: '',
      } as ManualReviewForm,
      table: {
        itemsPerPage: 25,
        headers: [
          { text: 'ID', value: 'id', sortable: false },
          { text: 'Project', value: 'projectId', sortable: false },
          { text: 'Product', value: 'productName', sortable: false },
          { text: 'Price', value: 'purchasePriceInCents' },
          { text: 'Purchase Date', value: 'orderCompletedAt', sortable: false },
        ],
      },

      loanOrderApplicationsTable: {
        itemsPerPage: 25,
        headers: [
          { text: 'ID', value: 'id', sortable: false },
          { text: 'Product Id', value: 'productId', sortable: false },
          { text: 'Product Name', value: 'productName', sortable: false },
          { text: 'Result', value: 'result', sortable: false },
          { text: 'Messages', value: 'messages', sortable: false },
          { text: 'Created at', value: 'createdAt', sortable: false },
          { text: 'Expired at', value: 'expiredAt', sortable: false },
          { text: 'Archived at', value: 'archivedAt', sortable: false },
          { text: 'Hotjar', value: 'hotjar', sortable: false },
        ],
      },
      checkoutSessionsTable: {
        itemsPerPage: 25,
        headers: [
          { text: 'Static Id', value: 'staticId', sortable: false },
          { text: 'Created At', value: 'createdAt', sortable: false },
          { text: 'Hotjar', value: 'hotjar', sortable: false },
        ],
      },
      // student info actions
      showChangeDialog: false,
      changeDialogEmail: '',
      changeDialogPhone: '',
      changeDialogPhoneCode: '',
      changeDialogErrorMessage: '',
      changeDialogStreet: '',
      changeDialogCity: '',
      changeDialogPostal: '',
      changeDialogRegion: '',
      changeDialogCountry: '',
      changeDialogFirstName: '',
      changeDialogLastName: '',

      zenMsg: '',
    };
  },

  async mounted() {
    await this.getData();
  },

  methods: {
    async getData() {
      try {
        this.state = 'loading';
        const data = await getStudent(this.$route.params.id);
        this.student = data;
        this.state = 'loaded';
      } catch (error) {
        this.state = 'error';
      }
    },

    showChangeInfoDialog(state: boolean) {
      if (state) {
        this.showChangeDialog = true;
      } else {
        this.showChangeDialog = false;
      }
      this.changeDialogEmail = '';
      this.changeDialogPhone = '';
      this.changeDialogPhoneCode = '';
      this.changeDialogErrorMessage = '';
      this.changeDialogEmail = '';
      this.changeDialogPhone = '';
      this.changeDialogPhoneCode = '';
      this.changeDialogErrorMessage = '';
      this.changeDialogStreet = '';
      this.changeDialogCity = '';
      this.changeDialogPostal = '';
      this.changeDialogRegion = '';
      this.changeDialogCountry = '';
    },

    hotjarNavigate(identifier: string) {
      let url = 'https://insights.hotjar.com/sites/2380012/playbacks?sort_by=-relevance_score&filters=%7B%22AND%22';
      url += ':%5B%7B%22DAYS_AGO%22:%7B%22created%22:365%7D%7D%2C%7B%22CONTAINS%22:%7B%22all_page_paths%22:%22';
      url += `${identifier}%22%7D%7D%5D%7D`;

      window.open(url, '_blank');
    },

    showupdateCreditCheckDialog() {
      this.updateCreditCheckDialog = true;
      if (this.student.creditCheck) {
        this.updateCreditCheckForm.hitStrength = this.student.creditCheck.hitStrength;
      }
    },

    showArchiveLoanOrderApplicationDialog(id: string) {
      this.archiveLoanOrderApplicationDialog = true;
      this.archiveLoanOrderApplicationDialogStagedId = id;
    },

    showArchiveCreditCheckDialog() {
      this.archiveCreditCheckDialog = true;
    },

    showManualReviewDialog() {
      this.manualReviewDialog = true;
    },

    async updateCreditCheck() {
      try {
        await updateCreditCheck(this.student.creditCheck.id, this.updateCreditCheckForm);
        this.updateCreditCheckDialog = false;
        this.getData();
      } catch (error) {
        this.updateCreditCheckState = 'error';
      }
    },

    async manualReviewCreditCheck() {
      try {
        this.manualReviewDialog = false;
        await manualReviewCreditCheck(this.student.creditCheck.id, this.manualReviewForm);
        this.getData();
      } catch (error) {
        this.manualReviewState = 'error';
      }
    },

    async archiveCreditCheck() {
      try {
        await archiveCreditCheck(this.student.creditCheck.id);
        this.archiveCreditCheckDialog = false;
        this.getData();
      } catch (error) {
        this.archiveCreditCheckState = 'error';
      }
    },

    async archiveKyc() {
      try {
        await archiveKyc(this.student.kycForm.id);
        this.archiveKycDialog = false;
        this.getData();
      } catch (error) {
        this.archiveKycState = 'error';
      }
    },

    async archiveLoanApplication() {
      try {
        await archiveLoanApplication(this.archiveLoanOrderApplicationDialogStagedId);
        this.archiveLoanOrderApplicationDialogStagedId = '';
        this.archiveLoanOrderApplicationDialog = false;
        this.getData();
      } catch (error) {
        this.archiveLoanOrderApplicationDialogState = 'error';
      }
    },

    // change info methods
    async updateStudent(id: string) {
      const rawPhoneNumber = this.changeDialogPhone?.replace(/\D/g, '');
      try {
        await updateStudent({
          id,
          street: this.changeDialogStreet || null,
          city: this.changeDialogCity || null,
          postal: this.changeDialogPostal || null,
          region: this.changeDialogRegion || null,
          country: this.changeDialogCountry || null,
          phone: rawPhoneNumber || null,
          phoneCode: this.changeDialogPhoneCode || null,
          email: this.changeDialogEmail || null,
          firstName: this.changeDialogFirstName || null,
          lastName: this.changeDialogLastName || null,
        });
        this.showChangeInfoDialog(false);
        await this.getData();
      } catch (e) {
        try {
          this.changeDialogErrorMessage = `👋 ${(e as any).data.message}`;
        } catch (e) {
          this.changeDialogErrorMessage = 'something went wrong';
        }
      }
    },

    // force send to zendesk
    async zendeskAction(id: string) {
      this.zenMsg = 'Pinged 👍';
      setTimeout(() => {
        this.zenMsg = '';
      }, 1000);

      await updateStudent({
        id,
        phone: this.student.info.phoneNumber,
        phoneCode: this.student.info.phoneCode,
        email: this.student.info.email,
        firstName: this.student.info.firstName.toUpperCase(),
        lastName: this.student.info.lastName.toUpperCase(),
      });
    },
  },
});
