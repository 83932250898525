












import Vue from 'vue';
import { getOrCreateAuthSession } from './dependencies';

export default Vue.extend({
  name: 'GetNewAppAuth',
  data() {
    return {
      loading: false,
      small: true,
      primary: true,
    };
  },
  methods: {
    async handleGetAuthCode() {
      this.loading = true;
      try {
        const { code, id: requestId } = await getOrCreateAuthSession();
        window.open(`${process.env.VUE_APP_NEW_ADMIN_URL}/verify-code/${requestId}?code=${code}`, '_blank');
      } catch (error) {
        this.$emit('error', error);
      } finally {
        this.loading = false;
      }
    },
  },
});
