import http from '@admin/http';
import { AxiosInstance } from 'axios';

function furnishReportCtor({ http }: { http: AxiosInstance }) {
  return async (reportId: string, devMode: boolean) => {
    await http.post<void>('/credit_reports/furnish', {
      reportId,
      devMode,
    });
  };
}

export const furnishReport = furnishReportCtor({ http });
