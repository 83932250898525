import http from '@admin/http';
import { AxiosInstance } from 'axios';

interface Response {
  id: string;
  name: string;
  url: string;
  reportDate: string;
  startDate: string;
  endDate: string;
  reportingEntity: string;
}

function getReportsCtor({ http }: { http: AxiosInstance }) {
  return async () => {
    const { data } = await http.get<Response[]>('/credit_reports');

    return data;
  };
}

export const getReports = getReportsCtor({ http });
