

























































































































































































import Vue from 'vue';
import { format, isBefore, parse } from 'date-fns';
import Dinero from 'dinero.js';
import { sortBy } from 'lodash';
import WbSelect from '@/components/select/index.vue';
import WbDialog from '@/components/dialog/index.vue';
import DelinquencyCard from './lnpl/delinquency_card.vue';
import {
  DelinquentAccount, updateOrder, updateOrderCharge, chargeInstallment,
} from '../../api';
import { ChargeType, OrderOrigin, OrderStatus } from '../../types';

interface StatusListOption {
  value: string;
  text: string;
}

interface Installments {
  id: string;
  status: string;
  currency: number;
  amountInCents: number;
  dueDate: string;
  retryDate: string | null;
  updatedAt: Date;
  paidAt: string;
  bluesnapTransactionId: string;
}

type NextInstallment = Installments & { processingDate: string }

interface Order {
  status: OrderStatus;
  priceInCents: number;
  currency: string;
  installments: Installments[];
  origin: OrderOrigin;
  chargeEnabled: boolean;
  chargeType: ChargeType;
}

export default Vue.extend({
  components: {
    WbSelect,
    WbDialog,
    DelinquencyCard,
  },
  props: {
    orderInfo: {
      type: Object,
      default: () => ({} as Order),
    },
    delinquencyInfo: {
      type: Object,
      default: () => (null as DelinquentAccount | null),
    },
  },
  data() {
    return {
      changeStatusOption: '',
      statusList: [] as StatusListOption[],
      changeStatusDialogState: false,
      confirmChargeDialogState: false,
      changeStatusLoading: false,
      confirmChargeLoading: false,
      resumeChargeDialogState: false,
      resumeChargeStatus: 'loaded',
      resumeChargeMessage: '',
      pastDueInstallment: [] as Installments[],
      openInstallments: [] as Installments[],
      nextInstallment: {} as NextInstallment | null,
    };
  },
  mounted() {
    this.showAvailableStatusUpdate();
    this.openInstallments = (this.orderInfo as Order).installments
      .filter((s) => ['future', 'failed', 'unpaid'].includes(s.status));
    this.nextInstallment = this.getNextChargeDate();
  },
  methods: {
    openUpdateOrderDialog() {
      this.changeStatusDialogState = true;
    },
    openResumeChargeDialog() {
      this.resumeChargeDialogState = true;
      this.pastDueInstallment = this.getInstallmentsPastDue();
    },
    openConfirmChargeDialog() {
      this.confirmChargeDialogState = true;
    },
    async changeOrderStatus() {
      this.changeStatusLoading = true;
      try {
        await updateOrder(this.$route.params.id, this.changeStatusOption);
      } catch (err) {
        if ((err as any).type) {
          this.$emit('setError', (err as any).message);
        } else {
          this.$emit('setError', 'Generic Error. Talk to dev team.');
        }
        return;
      } finally {
        this.changeStatusLoading = false;
        this.changeStatusDialogState = false;
        this.changeStatusOption = '';
      }
      this.$emit('getData');
      this.showAvailableStatusUpdate();
    },
    async enableOrderCharge() {
      this.resumeChargeStatus = 'loading';
      try {
        // For now it will always send true, later we'll add the ability to disable charges
        await updateOrderCharge(this.$route.params.id, true);
        this.$emit('getData');
      } catch (err) {
        if ((err as any).type) {
          this.$emit('setError', (err as any).message);
        } else {
          this.$emit('setError', 'Generic Error. Talk to dev team.');
        }
      } finally {
        this.resumeChargeStatus = 'loaded';
        this.resumeChargeDialogState = false;
      }
    },
    getPpmOptions(status: OrderStatus) {
      if (status === 'paid') {
        return [
          { value: 'canceled', text: 'Canceled' },
          { value: 'refund', text: 'Full Refund' },
        ];
      }
      return [];
    },
    getLnplOptions(status: OrderStatus) {
      if (status === 'chargebacked') {
        return [
          { value: 'chargeback_reversed', text: 'Reverse Chargeback' },
        ];
      }

      if (status === 'charge_off') {
        return [
          { value: 'chargeoff_bankrupt', text: 'Bankrupt' },
        ];
      }

      if (status !== 'paid') {
        return [];
      }

      return [
        { value: 'canceled', text: 'Canceled' },
        { value: 'chargeoff_bankrupt', text: 'Bankrupt' },
        { value: 'refund', text: 'Full Refund' },
      ];
    },
    showAvailableStatusUpdate() {
      const amountPaid = this.orderInfo.installments.reduce((p, c) => (
        c.status === 'paid' ? p + Number(c.amountInCents) : p
      ), 0);

      if (amountPaid >= Number(this.orderInfo.priceInCents)) {
        this.statusList = [];
        return;
      }

      if (this.orderInfo.origin === 'ppm') {
        this.statusList = this.getPpmOptions(this.orderInfo.status);
      }

      if (this.orderInfo.origin === 'lnpl') {
        this.statusList = this.getLnplOptions(this.orderInfo.status);
      }
    },
    getInstallmentsPastDue() {
      return (this.orderInfo as Order).installments.filter(
        (inst) => isBefore(new Date(inst.retryDate ?? inst.dueDate),
          new Date()) && ['failed', 'future', 'unpaid'].includes(inst.status),

      );
    },
    isRecurrentChargeActive() {
      return this.orderInfo.status === 'paid'
        && this.orderInfo.chargeType === 'manual'
        && this.openInstallments.length
        && this.nextInstallment;
    },
    getNextChargeDate(): NextInstallment | null {
      const futureInstallments = this.openInstallments
        .map((s) => ({
          ...s,
          processingDate: s.retryDate
            ? parse(s.dueDate, 'yyyy-MM-dd', new Date())
            : parse(s.dueDate, 'yyyy-MM-dd', new Date()),
        }));

      const sorted = sortBy(futureInstallments, (o) => o.dueDate);
      const first = sorted.shift();
      if (!first) {
        return null;
      }
      return { ...first, processingDate: format(first.processingDate, 'MM/dd/yyyy') };
    },
    async chargeNextInstallment() {
      this.confirmChargeLoading = true;
      try {
        await chargeInstallment(this.$route.params.id, this.nextInstallment!.id);
      } catch (err) {
        if ((err as any).type) {
          this.$emit('setError', (err as any).message);
        } else {
          this.$emit('setError', 'Generic Error. Talk to dev team.');
        }
      }
      this.confirmChargeLoading = false;
      this.$emit('getData');
      this.confirmChargeDialogState = false;
    },
    formatAmount(amount = 0) {
      return Dinero({ amount }).toFormat();
    },
  },
});

